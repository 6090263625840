import {
  SET_REPORT_TEMPLATE_CREATION_FORM,
  SET_ACTIVE_LOCATIONS_LOCATION_REPORT,
  SET_LOCATION_REPORT_FILTER,
  SET_ASSET_REPORT_FILTER,
  SET_GENERAL_REPORT_FILTER,
  SET_CURRENT_GENERAL_REPORT,
  SET_REPORT_TEMPLATE_FILTER_CRITERIA,
  SET_REPORT_LIST_FILTER_CRITERIA,
  SET_CURRENT_REPORT_TEMPLATE,
  SET_REPORT_DATA_FROM_SEARCH,
  SET_ACTIVE_REPORT_TEMPLATE,
  SET_COMAPRE_FIELDS_DATA,
  SET_REPORT_COMPARED_DATA,
  SET_COMPARE_PRESELECT_DATA,
  SET_SHORTED_REPORT_TEMPLATES,
  SET_USERS_FOR_ASSIGN,
  CLEAR_ACTIVE_TEMPLATE,
  CLEAR_REPORT_LIST_STATE,
  SET_ACTIVE_TEMPLATE,
  TOGGLE_AUTO_SAVE
} from '../constants/reduceConstants';

const initialState = {
  filterCriteria: {},
  shortedReportTemplatesList: [],
  reportTemplateFilterCriteria: [],
  reportTemplate: {},
  reportDataFromSearch: [],
  locationReportFilter: {},
  assetReportFilter: {},
  generalReportFilter: {},
  projectFilterList: [],
  activeLocationsLocationReport: [],
  activeTemplate: {},
  autoSaveEnabled: false,
  activeReportTemplate: {},
  currentGeneralReport: {},
  usersForAssignList: [],
  reportTemplateCreationForm: [],
  comparePreselectData: {},
  compareFields: [],
  reportComparedData: []
};

const reportListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REPORT_TEMPLATE_FILTER_CRITERIA:
      return {
        ...state,
        reportTemplateFilterCriteria: payload
      };
    case SET_REPORT_LIST_FILTER_CRITERIA:
      return {
        ...state,
        filterCriteria: payload
      };
    case SET_SHORTED_REPORT_TEMPLATES:
      return {
        ...state,
        shortedReportTemplatesList: payload
      };
    case SET_CURRENT_REPORT_TEMPLATE:
      return {
        ...state,
        reportTemplate: payload
      };
    case SET_REPORT_DATA_FROM_SEARCH:
      return {
        ...state,
        reportDataFromSearch: payload
      };
    case SET_LOCATION_REPORT_FILTER:
      return {
        ...state,
        locationReportFilter: payload
      };
    case SET_ASSET_REPORT_FILTER:
      return {
        ...state,
        assetReportFilter: payload
      };
    case SET_GENERAL_REPORT_FILTER:
      return {
        ...state,
        generalReportFilter: payload
      };
    case SET_CURRENT_GENERAL_REPORT:
      return {
        ...state,
        currentGeneralReport: payload
      };
    case SET_ACTIVE_LOCATIONS_LOCATION_REPORT:
      return {
        ...state,
        activeLocationsLocationReport: payload
      };
    case SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: payload
      };
    }
    case TOGGLE_AUTO_SAVE: {
      return {
        ...state,
        autoSaveEnabled: payload
      };
    }
    case SET_ACTIVE_REPORT_TEMPLATE:
      return {
        ...state,
        activeReportTemplate: payload
      };
    case SET_USERS_FOR_ASSIGN:
      return {
        ...state,
        usersForAssignList: payload
      };
    case SET_REPORT_TEMPLATE_CREATION_FORM:
      return {
        ...state,
        reportTemplateCreationForm: payload
      };
    case SET_COMAPRE_FIELDS_DATA:
      return {
        ...state,
        compareFields: payload
      };
    case SET_COMPARE_PRESELECT_DATA:
      return {
        ...state,
        comparePreselectData: payload
      };
    case SET_REPORT_COMPARED_DATA:
      return {
        ...state,
        reportComparedData: payload
      };
    case CLEAR_ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: {},
        activeReportTemplate: {}
      };
    case CLEAR_REPORT_LIST_STATE:
      return {
        ...initialState,
        locationReportFilter: state.locationReportFilter,
        assetReportFilter: state.assetReportFilter,
        generalReportFilter: state.generalReportFilter,
        activeTemplate: state.activeTemplate,
        activeReportTemplate: state.activeReportTemplate,
        comparePreselectData: state.comparePreselectData
      };
    default:
      return state;
  }
};

export default reportListReducer;
