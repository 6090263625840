import React from 'react';
import styles from './RandRDashboardTable.module.scss';

import { NoDataTableRow, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import StyledLink from 'components/StyledLink';

import { Table, TableHead, TableBody } from '@material-ui/core';

import { generateCellUrl } from '../../../helpers';

const COLUMNS = ['Capacity', 'On Roster', 'On Site', 'Available'];

export default function RandRDashboardTable({ data }) {
  const { capacities, total } = data;

  const generateUrlParams = (capacityId, dashboardField) => ({
    dashboardId: data.id,
    dashboardType: 'RandRDashboard',
    dashboardField,
    capacityId
  });

  return (
    <div className={styles.table}>
      <TableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {COLUMNS.map((name, index) => (
                <StyledTableCell key={index}>{name}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {capacities?.length ? (
              <>
                {capacities.map(({ capacity, randRInfo }, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className={styles.capacityCell}>
                      {capacity?.name || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.onRosterCell}>
                      <StyledLink
                        to={generateCellUrl(generateUrlParams(capacity.id, 'OnRoster'))}
                        isRedirectAvailable={randRInfo?.onRoster > 0}>
                        {randRInfo?.onRoster || 0}
                      </StyledLink>
                    </StyledTableCell>
                    <StyledTableCell className={styles.onSiteCell}>
                      <StyledLink
                        to={generateCellUrl(generateUrlParams(capacity.id, 'OnSite'))}
                        isRedirectAvailable={randRInfo?.onSite > 0}>
                        {randRInfo?.onSite || 0}
                      </StyledLink>
                    </StyledTableCell>
                    <StyledTableCell className={styles.availableCell}>
                      <StyledLink
                        to={generateCellUrl(generateUrlParams(capacity.id, 'available'))}
                        isRedirectAvailable={randRInfo?.available > 0}>
                        {randRInfo?.available || 0}
                      </StyledLink>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow className={styles.total}>
                  <StyledTableCell>TOTAL</StyledTableCell>
                  <StyledTableCell>{total?.onRoster || 0}</StyledTableCell>
                  <StyledTableCell>{total?.onSite || 0}</StyledTableCell>
                  <StyledTableCell>{total?.available || 0}</StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <NoDataTableRow isTableFiltered={!!data?.department?.id} />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
