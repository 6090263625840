import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './FormSearchInput.module.scss';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import clsx from 'clsx';

export default function FormSearchInput({
  name,
  options,
  onSelect,
  isDisabled,
  classes,
  disableError,
  clearable,
  index,
  optionType,
  includeMissingOption = false
}) {
  const { control, clearErrors } = useFormContext();

  const handleOptionSelect = (name, value, isInvalid) => {
    isInvalid && clearErrors(name);
    onSelect(name, value, index);
  };

  const getOptions = (selected) => {
    if (!options?.length) return [];

    if (!selected?.id) return options;

    const isSelectedInOptions = options.some(({ id }) => id === selected?.id);

    if (isSelectedInOptions) {
      return options;
    }

    return [...options, selected].sort((a, b) => (a.id > b.id ? 1 : -1));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(!!classes && classes, styles.inputWrapper)}>
          <InputSearchWithMultiselect
            name={name}
            defaultValue={value?.id || ''}
            options={includeMissingOption ? getOptions(value) : options || []}
            onFilterSelect={(name, value) => handleOptionSelect(name, value, !!error?.message)}
            multiselect={false}
            isInvalid={disableError ? false : !!error}
            disabled={isDisabled}
            clearable={clearable}
            index={index}
            optionType={optionType}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
