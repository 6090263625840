import api from '../api';
import {
  SET_ACTIVE_LOCATIONS_LOCATION_REPORT,
  CLEAR_ACTIVE_TEMPLATE,
  CLEAR_REPORT_LIST_STATE,
  SET_LOAD_MORE_FLAG,
  SET_USERS_FOR_ASSIGN,
  SET_REPORT_TEMPLATE_FILTER_CRITERIA,
  SET_REPORT_TEMPLATE_CREATION_FORM,
  SET_CURRENT_REPORT_TEMPLATE,
  SET_REPORT_DATA_FROM_SEARCH,
  SET_ACTIVE_REPORT_TEMPLATE,
  SET_LOCATION_REPORT_FILTER,
  SET_ASSET_REPORT_FILTER,
  SET_GENERAL_REPORT_FILTER,
  SET_CURRENT_GENERAL_REPORT,
  SET_SHORTED_REPORT_TEMPLATES,
  SET_COMAPRE_FIELDS_DATA,
  SET_REPORT_COMPARED_DATA,
  SET_COMPARE_PRESELECT_DATA,
  SET_ACTIVE_TEMPLATE,
  TOGGLE_AUTO_SAVE,
  SET_REPORT_LIST_FILTER_CRITERIA
} from '../constants/reduceConstants';
import {
  formatDate,
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  serializeError
} from 'helpers/AppHelpers';
import {
  SUCCESS_SAVE_REPORT,
  SUCCESS_SUBMIT_REPORT,
  SUCCESS_CREATE_REPORT_TEMPLATE,
  SUCCESS_UPDATE_REPORT_TEMPLATE,
  SUCCESS_PUBLISH_REPORT_TEMPLATE,
  SUCCESS_DELETE_REPORT_TEMPLATE,
  SUCCESS_DELETE_GENERAL_REPORT
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import {
  LOCATION_REPORT_LIST_PATH,
  ASSET_REPORT_LIST_PATH,
  GENERAL_REPORT_LIST_PATH,
  REPORT_TEMPLATE_LIST_PATH,
  REPORT_TEMPLATE_EDITOR
} from '../constants/routeConstants';
import { captureException } from '@sentry/react';

export const getFilterCriteria = (q) => (dispatch) =>
  api.reportList
    .getFilterCriteriaApi(q)
    .then((res) => {
      dispatch({ type: SET_REPORT_LIST_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getLocationWeather =
  ({ lat, lng, date }) =>
  () => {
    const weatherQuery = {
      windspeed_unit: 'mph',
      temperature_unit: 'fahrenheit',
      current_weather: true,
      latitude: lat,
      longitude: lng,
      daily: ['temperature_2m_max', 'temperature_2m_min', 'windspeed_10m_max', 'weathercode'],
      timezone: 'auto',
      start_date: formatDate(date),
      end_date: formatDate(date)
    };
    return api.reportList
      .getLocationWeatherApi(weatherQuery)
      .then((res) => res)
      .catch((err) => console.log(err));
  };

export const getGeneralPdfResource = (id) => () => {
  return api.reportList
    .getGeneralPdfResourceApi(id)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getUsersForAssign = (query) => (dispatch) => {
  return api.reportList
    .getUserForAssignApi(query)
    .then((res) => dispatch({ type: SET_USERS_FOR_ASSIGN, payload: res }))
    .catch((err) => console.log(err));
};

export const getActiveLocationsLocationRepot = (query) => (dispatch) => {
  api.reportList
    .getActiveLocationsListApi(query)
    .then((res) => dispatch({ type: SET_ACTIVE_LOCATIONS_LOCATION_REPORT, payload: res }))
    .catch((err) => console.log(err));
};

export const getLocationsAndProjects = () => () =>
  api.reportList
    .getLocationsAndProjectsApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getReportTemplateCreationForm = () => (dispatch) => {
  return api.reportList
    .getReportTemplateCreationFormApi()
    .then((res) => {
      dispatch({ type: SET_REPORT_TEMPLATE_CREATION_FORM, payload: res });
      return true;
    })
    .catch((err) => console.log(err));
};

export const getReportTemplateFilterCriteria = () => (dispatch) => {
  api.reportList
    .getReportTemplateFilterCriteriaApi()
    .then((res) => dispatch({ type: SET_REPORT_TEMPLATE_FILTER_CRITERIA, payload: res }))
    .catch((err) => console.log(err));
};

export const searchReportTemplateByLocationId = (id) => (dispatch) =>
  api.reportList
    .searchReportTemplateByLocationIdApi(id)
    .then((res) => {
      dispatch({ type: SET_REPORT_DATA_FROM_SEARCH, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const searchReportTemplateByAssetId = (id) => (dispatch) => {
  api.reportList
    .searchReportTemplateByAssetIdApi(id)
    .then((res) => dispatch({ type: SET_REPORT_DATA_FROM_SEARCH, payload: res }))
    .catch((err) => console.log(err));
};

export const setActiveTemplate = (template) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_TEMPLATE, payload: template });
};

export const getGeneralReportList = (query) => (dispatch) => {
  return api.reportList
    .getGeneralReportListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLastSavedGeneralReport = (data) => () => {
  return api.reportList
    .getLastSavedGeneralReportApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getSingleGeneralReport = (id) => (dispatch) => {
  api.reportList
    .getSingleGeneralReportApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_GENERAL_REPORT, payload: res }))
    .catch((err) => console.log(err));
};

export const upgradeGeneralReport = (data, options) => (dispatch) => {
  const { type, shouldBlur, includeNotification, isAutoSave } = options;
  const {
    reportType: { name }
  } = data;
  const redirectUrl =
    name === 'Asset'
      ? ASSET_REPORT_LIST_PATH
      : name === 'Location'
      ? LOCATION_REPORT_LIST_PATH
      : name === 'General'
      ? GENERAL_REPORT_LIST_PATH
      : '/';

  const successAction = (res) => {
    if (!shouldBlur) {
      enqueueSuccessSnackbar(type === 'save' ? SUCCESS_SAVE_REPORT : SUCCESS_SUBMIT_REPORT);
      browserHistory.push(redirectUrl);
      dispatch({ type: CLEAR_ACTIVE_TEMPLATE });
    }
    return res;
  };
  const errorAction = (err) => {
    const errors = JSON.parse(err.request.response);
    enqueueErrorSnackbar('', errors);
    return errors;
  };

  const handleAutoSaveError = async (err) => {
    const is401 = err?.response?.status === 401;

    if (!is401) {
      await captureException(err, serializeError(err));
    }
    return { status: 'error' };
  };

  if (isAutoSave) {
    if (data.id) {
      return api.reportList
        .updateGeneralReportApiWithoutLoader(data, includeNotification)
        .then((res) => successAction(res))
        .catch(handleAutoSaveError);
    } else {
      return api.reportList
        .createGeneralReportApiWithoutLoader(data, includeNotification)
        .then((res) => successAction(res))
        .catch(handleAutoSaveError);
    }
  } else if (data.id) {
    return api.reportList.updateGeneralReportApi(data, includeNotification).then(
      (res) => successAction(res),
      (err) => errorAction(err)
    );
  } else {
    return api.reportList.createGeneralReportApi(data, includeNotification).then(
      (res) => successAction(res),
      (err) => errorAction(err)
    );
  }
};

export const updateReport = (data) => () => {
  return api.reportList.updateReportApi(data).then(
    (res) => res,
    (err) => console.log(err)
  );
};

export const removeGeneralReport = (data) => () => {
  const {
    reportType: { name }
  } = data;
  const redirectUrl =
    name === 'Asset'
      ? ASSET_REPORT_LIST_PATH
      : name === 'Location'
      ? LOCATION_REPORT_LIST_PATH
      : name === 'General'
      ? GENERAL_REPORT_LIST_PATH
      : '/';

  return api.reportList.removeGeneralReportApi(data.id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_GENERAL_REPORT);
      browserHistory.push(redirectUrl);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getShortedReportTemplates = (query) => (dispatch) => {
  return api.reportList
    .getShortedReportTemplatesApi(query)
    .then((res) => {
      dispatch({ type: SET_SHORTED_REPORT_TEMPLATES, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getReportTemplate = (id) => (dispatch) => {
  api.reportList
    .getReportTemplateApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_REPORT_TEMPLATE, payload: res }))
    .catch((err) => console.log(err));
};

export const copyReportTemplate = (id) => (dispatch) => {
  api.reportList
    .getReportTemplateApi(id)
    .then((res) => {
      const responseCopy = { ...res, name: '', published: false };
      delete responseCopy.id;
      delete responseCopy.publishedAtUtc;
      dispatch({ type: SET_ACTIVE_REPORT_TEMPLATE, payload: responseCopy });
      browserHistory.push(REPORT_TEMPLATE_EDITOR);
    })
    .catch((err) => console.log(err));
};

export const checkReportTemplateName = (query) => () => {
  return api.reportList
    .checkReportTemplateNameApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const createReportTemplate = (data) => () => {
  return api.reportList.createReportTemplateApi(data).then(
    (res) => {
      if (res.published) {
        enqueueSuccessSnackbar(SUCCESS_PUBLISH_REPORT_TEMPLATE(res.name));
        browserHistory.push(REPORT_TEMPLATE_LIST_PATH);
      } else {
        enqueueSuccessSnackbar(SUCCESS_CREATE_REPORT_TEMPLATE(res.name));
        return res;
      }
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateReportTemplate = (data) => () => {
  return api.reportList.updateReportTemplateApi(data).then(
    (res) => {
      if (res.published) {
        enqueueSuccessSnackbar(SUCCESS_PUBLISH_REPORT_TEMPLATE(res.name));
        browserHistory.push(REPORT_TEMPLATE_LIST_PATH);
      } else {
        enqueueSuccessSnackbar(SUCCESS_UPDATE_REPORT_TEMPLATE(res.name));
        return res;
      }
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteReportTemplate = (id, callback) => () => {
  return api.reportList.deleteReportTemplateApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_REPORT_TEMPLATE);
      browserHistory.push(REPORT_TEMPLATE_LIST_PATH);
      if (callback) callback();
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getCompareFields = (id) => (dispatch) => {
  api.reportList
    .getCompareFieldsApi(id)
    .then((res) => {
      dispatch({ type: SET_COMAPRE_FIELDS_DATA, payload: res });
    })
    .catch((err) => console.log(err));
};

export const getReportComparedData = (data) => (dispatch) => {
  api.reportList
    .getReportComparedDataApi(data)
    .then((res) => {
      dispatch({ type: SET_REPORT_COMPARED_DATA, payload: res });
      dispatch({ type: SET_COMPARE_PRESELECT_DATA, payload: { ...data, pageNumber: 1 } });
    })
    .catch((err) => console.log(err));
};

export const getCompareDataSerialized = (data) => () => {
  return api.reportList
    .getCompareDataSerializedApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getArchiveResource = (query) => () =>
  api.reportList
    .getArchiveResourceApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setLocationReportFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_REPORT_FILTER, payload });
};
export const setAssetReportFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_REPORT_FILTER, payload });
};
export const setGeneralReportFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_GENERAL_REPORT_FILTER, payload });
};

export const clearComparePreselectData = () => (dispatch) => {
  dispatch({ type: SET_COMPARE_PRESELECT_DATA, payload: {} });
};

export const toggleAutoSave = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_AUTO_SAVE, payload });
};
export const clearActiveTemplate = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_TEMPLATE });
};

export const clearReportListState = () => (dispatch) => {
  dispatch({ type: CLEAR_REPORT_LIST_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
